import api from "@axios";

// FOR SPECIAL PRICES
class SpecialPrice {
    // Get all prices
    static async fetch(userId) {
        const { data } = await api.get(`/special-prices/${userId}`);
        return data;
    }
    // Save changes
    static async apply(userId, pricesData) {
        const { data } = await api.post(`/special-prices/${userId}`, pricesData);
        return data
    }
}

export default SpecialPrice;