
 
<template>
  <div>
    <!-- User section -->
    <UserSection :userId="userId" />
    <!-- Form Inputs -->
    <b-form ref="form" :style="{ height: trHeight }" class="repeater-form">
      <!-- Row Loop -->
      <b-row v-for="(item, index) in prices" :key="`f${index}`" ref="row">
        <!-- service name -->
        <b-col md="4">
          <b-form-group label="Service Name" label-for="serviceName">
            <v-select
              id="serviceName"
              class="select-list"
              :value="services.find((s) => s.id == prices[index].service_id)"
              @input="prices[index].service_id = $event.id"
              label="name"
              :options="services"
              placeholder="Select service"
            />
          </b-form-group>
        </b-col>

        <!-- price -->
        <b-col md="4">
          <b-form-group label="Price" label-for="price">
            <b-form-input
              v-model.number="prices[index].price"
              type="number"
              :placeholder="defaultPrice(index)"
            />
          </b-form-group>
        </b-col>

        <!-- Remove Button -->
        <b-col lg="4" md="4" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeItem(index)"
          >
            <feather-icon icon="XIcon" class="mr-25" />
            <span>Delete</span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>
    </b-form>
    <!-- Footer actions -->
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="addItem"
      class="mr-1"
    >
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>Add New</span>
    </b-button>
    <b-button
      @click="apply"
      :disabled="loading"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
    >
      <b-spinner class="mr-1" v-if="loading" small />
      <feather-icon icon="CheckIcon" class="mr-25" />
      <span>Apply Changes</span>
    </b-button>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import SpecialPrice from "@/services/special-price.service.js";
import Services from "@/services/services.service.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserSection from "@/components/UI/UserSection.vue";

export default {
  mixins: [heightTransition],
  data: () => ({
    loading: false,
    services: [],
    prices: [],
  }),
  async mounted() {
    this.loading = true;
    this.prices = await SpecialPrice.fetch(this.userId);
    this.initTrHeight();
    this.services = await Services.fetch();
    this.loading = false;
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  computed: {
    userId() {
      return this.$route.params.user_id;
    },
    allowed() {
      return true;
    },
  },
  methods: {
    defaultPrice(index) {
      const originalPrice = this.services.find(
        (s) => s.id == this.prices[index].service_id
      )?.price;
      return originalPrice
        ? `Default price: ${originalPrice}`
        : "Service price";
    },
    addItem() {
      this.prices.push({ service_id: null, price: null });
      // Adjust height
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.prices.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    // Apply changes
    async apply() {
      this.loading = true;
      try {
        await SpecialPrice.apply(this.userId, this.prices);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Saved changes successfully",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (e) {
        const err =
          e.response.status == 422
            ? Object.values(e.response.data.errors)[0][0]
            : e.response.status == 403
            ? e.response.data
            : "Something went wrong, please try again";
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Faild to add",
            icon: "XIcon",
            text: err,
            variant: "danger",
          },
        });
      }
      this.loading = false;
    },
  },
  directives: {
    Ripple,
  },
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-component
    ToastificationContent,
    UserSection,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.repeater-form {
  transition: 0.35s height;
}
#vs1__listbox {
  z-index: 99 !important;
}
</style>